<template>
  <div class="pa-1">
    <v-row>
      <v-col cols="3" v-for="i in 4" :key="i">
        <v-card outlined>
          <v-card-subtitle class="my-0 pb-0 font-weight-black">Total Exams</v-card-subtitle>
          <v-layout class="my-0 py-0">
            <v-flex align-self-center lg3>
              <v-btn small tile color="blue" class="ml-2"> 
                <v-icon>mdi-anchor</v-icon>
              </v-btn>
            </v-flex>
            <v-flex>
              <v-card-subtitle class="my-0 font-weight-black">25</v-card-subtitle>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
    <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInit"></data-iterator>
  </div>
</template>
<script>
export default {
  data () {
    return {
      reInit: 0,
      payload: {
        create: () => this.$router.push('/exam/actions'),
        items: [],
        showSelect: false,
        actionsList: [
          {
            click: (item) => this.editHandler(item._id),
            icon: 'mdi-pencil',
            color: 'primary'
          },
          {
            click: (item) => this.deleteHandler(item._id),
            icon: 'mdi-delete',
            color: 'error'
          }
        ]
      },
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  mounted () {
    this.getListHandler()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Title',
        value: 'name'
      }, {
        text: 'Description',
        value: 'description'
      }, {
        text: 'Subject',
        value: 'subject'
      }, {
        text: 'Date',
        value: 'Date'
      }, {
        text: 'Status',
        value: 'status'
      }, {
        text: 'Results',
        value: 'results'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getListHandler () {
      this.$_execute('get', 'exams').then(({ data }) => {
        this.payload.items = data
        this.reInit++
      })
    },
    editHandler (id) {
      this.$router.push(`/exam/actions/${id}`)
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'exams',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
